/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

// this is used for the commented-out Form below
// import { useForm } from 'react-hook-form';
import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';
import { Col, Container } from 'components/UI';
// this is used for the commented-out Form below
// import { FormFile } from 'components/UI/Forms/FormFile';
import { Breakpoints } from 'utils/enums';

const JobsSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(5),
  position: 'relative',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(24),
  },
}));

const Content = styled('article')(({ theme }) => ({
  '& h1': {
    [theme.breakpoints.up(Breakpoints.Md)]: {
      ...theme.typography.h2,
    },
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(0),
  },

  '& li': {
    ...theme.typography.body1,
    listStyle: 'disc',
    margin: theme.spacing(0, 0, 4, 5),
    position: 'relative',
  },

  '& ol': {
    paddingLeft: theme.spacing(5),
  },

  '& p': {
    margin: theme.spacing(0, 0, 12.5),
  },

  '& p, ol': {
    ...theme.typography.body1,
  },

  '& ul': {
    listStyleType: 'none',
    marginBottom: theme.spacing(16),
    paddingLeft: theme.spacing(0),
  },

  margin: theme.spacing(10, 0),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& h1': {
      ...theme.typography.h3,
      fontWeight: 500,
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(0),
    },

    '& ol': {
      margin: theme.spacing(0, 0, 16),
      paddingLeft: theme.spacing(8.5),
    },

    '& p': {
      '&:last-child': {
        margin: theme.spacing(0, 0, 16),
      },

      margin: theme.spacing(0, 0, 7),
    },

    '& ul': {
      listStyleType: 'none',
      marginBottom: theme.spacing(16),
      paddingLeft: theme.spacing(0),
    },
    marginBottom: theme.spacing(29),
    marginTop: theme.spacing(0),
    position: 'relative',
    zIndex: 2,
  },
}));

const Location = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.up(Breakpoints.Md)]: theme.typography.body1,
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: theme.typography.h1,
}));

// const InputWrapper = styled('div')(({ theme }) => ({
//   marginBottom: theme.spacing(10),
// }));

// const Form = styled('form')(({ theme }) => ({
//   marginBottom: theme.spacing(10),
//   maxWidth: theme.typography.pxToRem(568),
// }));

const JobsContentTemplate: React.FC<Props> = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  // const { register, handleSubmit, errors } = useForm();

  // eslint-disable-next-line no-console
  // const onSubmit = (data: DataProps) => console.log(data);

  return (
    <Overflow>
      <Layout title={frontmatter.title} description={frontmatter.description}>
        <JobsSection>
          <Container>
            <Col md={5}>
              <Location>{frontmatter.location}</Location>
              <Title variant="h4">{frontmatter.title}</Title>
            </Col>
            <Col md={6} sm={12}>
              <Content>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Content>
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                <InputWrapper>
                  <FormInput
                    name="full_name"
                    label="Full Name *"
                    errorMessage={
                      errors.full_name ? 'This field is required' : undefined
                    }
                    placeholder="Type your name"
                    type="text"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormInput
                    name="email"
                    label="Email *"
                    errorMessage={
                      errors.email ? 'This field is required' : undefined
                    }
                    placeholder="Type your email"
                    type="email"
                    ref={register({
                      pattern: {
                        message: '',
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      },
                      required: true,
                    })}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormInput
                    name="phone_number"
                    label="Phone Number *"
                    errorMessage={
                      errors.phone_number ? 'This field is required' : undefined
                    }
                    placeholder="Type your phone number"
                    type="text"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormInput
                    name="city"
                    label="Location (City) *"
                    errorMessage={
                      errors.city ? 'This field is required' : undefined
                    }
                    placeholder="Type your location"
                    type="text"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormFile
                    name="file"
                    label="Resume/CV *"
                    error={!!errors.file}
                    ref={register({
                      required: true,
                    })}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormInput
                    name="linkedin"
                    label="LinkedIn Profile"
                    placeholder="Type your linkedin profile url"
                    type="text"
                    ref={register}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormInput
                    name="website"
                    label="Website"
                    placeholder="Type your website url"
                    type="text"
                    ref={register}
                  />
                </InputWrapper>
                <Button fullWidth type="submit" onClick={onSubmit}>
                  Submit
                </Button>
              </Form> */}
            </Col>
          </Container>
        </JobsSection>
      </Layout>
    </Overflow>
  );
};

interface JobsProps extends MetaData {
  path: string;
  title: string;
  location: string;
}

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: JobsProps;
    };
  };
}

// interface DataProps {
//   fullName?: string;
//   email?: string;
//   phone?: string;
//   city?: string;
//   linkedIn?: string;
//   website?: string;
// }

export const pageQuery = graphql`
  query ($route: String!) {
    markdownRemark(frontmatter: { path: { eq: $route } }) {
      html
      frontmatter {
        path
        title
        description
        location
        visible
      }
    }
  }
`;

export default JobsContentTemplate;
